<template>
  <exception-page type="404" />
</template>

<script>
import ExceptionPage from './ExceptionPage'

export default {
  components: {
    ExceptionPage
  }
}
</script>
