const types = {
    404: {
        img: '/images/404.svg',
        title: '404',
        desc: '抱歉，你访问的页面不存在'
    },
    500: {
        img: '/images/500.svg',
        title: '500',
        desc: '抱歉，服务器出错了'
    }
}
  
export default types
  